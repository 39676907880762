@import './rem.scss';

.wallets-bar {
  padding: rem(70px) rem(50px) rem(100px) rem(50px);
  background: linear-gradient(304.39deg, #233062 17.76%, #18203F 49.62%, #0C0F16 93.64%);
}

.wallets-bar .text, .wallets-bar3 .text {
  max-width: rem(600px);
  line-height: rem(24px);
  text-align: left;
  color: #b2b2b2;
  font-size: rem(14px);
}

.wallets-bar .div-text {
  flex: rem(3px);
}

.wallets-bar2 {
  padding: rem(70px) rem(50px) rem(100px) rem(50px);
  //background: linear-gradient(304.39deg, #e5eae7 17.76%, #cac8cc 49.62%, #e1e1ea 93.64%);
  background-color: #F6F3EA;
}

.wallets-bar2 h1 {
  color: #202020;
}

.wallets-bar2 .additional {
  text-align: left;
  border: 1px dotted #aaaaaa;
  padding: rem(20px);
  border-radius: rem(5px);
  line-height: rem(24px);
}

.wallets {
  flex-direction: column;
  position: relative;
  border: 0px solid red;
  display: flex;
  justify-content: center;
  margin: 0 rem(-10px);
}

.wallets2 {
  flex-direction: row;
  display: flex;
  margin-bottom: rem(30px);
}

.wallets2 .text {
  text-align: left;
  padding: rem(12px) rem(10px);
  color: #444444;
  line-height: rem(24px);
  font-size: rem(14px);
}

.wallets2 .text .name {
  font-size: rem(16px);
}


.wallets2 .text a {
  display: inline-block;
  padding-top: rem(5px);
  color: #b5882e;
  font-size: rem(12px);
  text-decoration: underline;
  margin-right: rem(8px);
}

.wallets2 .text a:hover {
  color: #c59432;
  font-size: rem(12px);
  text-decoration: underline;
}

.wallets2 .text .small {
  position: relative;
  top: -2px;
  font-size: rem(10px);
  background: #b5882e22;
  border: 1px solid #b5882e44;
  padding: 3px rem(6px) 2px rem(6px);
  border-radius: rem(20px);
  left: rem(6px);
  margin-left: rem(5px);

}

.img-wallets {
  margin: rem(10px);
  height: rem(160px);
  flex: 0 0 rem(240px);
  border: 0 transparent;
  border-radius: rem(4px);
  z-index: 1;
  display: block;
  background-size: cover;
  box-sizing: border-box;
}

.img-wallets.metamask {
  background: #ccc3bf url('/img/metamask.png') center center no-repeat;
  background-size: 50%;
}

.img-wallets.mywallets {
  background: #b6ccba url('/img/mywallets.png') center center no-repeat;
  background-size: 50%;
}

.img-wallets.guarda {
  background: #cccccc url('/img/guarda.png') center center no-repeat;
  background-size: 130%;
}

.img-wallets.nano {
  background: #cccccc url('/img/nano.png') center center no-repeat;
  background-size: 120%;
}

.img-wallets.trezor {
  background: #cccccc url('/img/trezor.png') center center no-repeat;
  background-size: 60%;
}

.wallets-bar3 h1 {
  margin-bottom: rem(10px);
}