@import './rem.scss';

.copy {
  background: url("/img/copy.svg") no-repeat center center;
  position: relative;
  top: rem(6px);
  height: rem(27px);
  width: rem(27px);
  line-height: rem(26px);
  display: inline-block;
  padding: rem(2px) rem(2px);
  color: white;
  cursor: pointer;
  font-size: rem(11px);
  border-radius: rem(20px);
  flex-grow: 0;
}

.copy:hover {
  background: #34831322 url("/img/copy.svg") no-repeat center center;;
}

.copy.small {
  line-height: rem(10px);
  padding: rem(0px) rem(2px);
  top: rem(-4px);
  height: rem(20px);
  width: rem(20px);
  background-size: 50%;
  margin-bottom: rem(-10px);
}

.copy .copyarea {
  width: 0;
  height: 0;
}