@import './rem.scss';

@media screen and (min-width: 10px) {
    .about-bar .div-image {
        width: 0;
    }

    .about-bar .gold {
        width: 0;
        margin: rem(134px) rem(30px);
    }

    .about-bar .logo-bar {
        background: url('/img/gold2.svg') no-repeat center;
        background-size: 50%;
    }

}

@media screen and (min-width: 768px) {
    .about-bar .div-image {
        width: rem(300px);
    }

    .about-bar .gold {
        width: rem(166px);
        margin: rem(134px) rem(30px);
    }

    .about-bar .logo-bar {
        background: none;
    }
}
