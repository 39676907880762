@import './rem.scss';

@font-face {
  //FontMedium
  font-family: HelveticaNeueCyr;
  font-weight: 600;
  src: url("/fonts/HelveticaNeueCyr-Medium.otf") /* EOT file for IE */
}

@font-face {
  //FontRoman
  font-family: HelveticaNeueCyr;
  font-weight: 500;
  src: url("/fonts/HelveticaNeueCyr-Roman.otf") /* EOT file for IE */
}

@font-face {
  //FontLight
  font-family: HelveticaNeueCyr;
  font-weight: 400;
  src: url("/fonts/HelveticaNeueCyr-Light.otf") /* EOT file for IE */
}

.border {
  border: rem(1px) solid red;
}

.root {
  min-height: 100%;
}

body {
  outline: none;
  font-family: HelveticaNeueCyr, sans-serif;
  background-color: #202020;
}

html, body, .viewport {
  width: 100%;
  height: 100%;
  margin: 0;
}

footer {
  display: block;
}

.top-line-header {

  display: block;
  height: rem(50px);
  width: auto;
  background-color: #141414;
  padding: 0 rem(50px);
}

.top-line-header-logo {
  display: block;
  width: auto;
  background-color: #000000;
  padding: 0 rem(50px);
}

.top-line-header-logo .logo {
  background: url("/img/logo.png") no-repeat center center;
  background-size: 100%;
  width: rem(190px);
  height: rem(42px);
  flex: 0 0 rem(190px);
}

.top-line-header .logo {
  background: url('/img/logo.png') no-repeat center center;
  background-size: 100%;
  width: rem(190px);
  height: rem(42px);
  flex: 0 0 rem(190px);
}

.right {
  text-align: right;
}

menu {
  margin-top: rem(18px);
  border: 0px solid red;
}

menu a {
  font-size: rem(13px);
  font-weight: 500;
  text-decoration: none;
  text-transform: uppercase;
  color: #dad7cf;
}

menu a.active {
  font-weight: 500;
  color: #FFFFFF;
  text-shadow: 0 0 rem(16px) rgba(255, 255, 255, 0.65);
}

menu a:hover {
  color: #FFFFFF;
  text-shadow: 0 0 rem(16px) rgba(255, 255, 255, 0.65);
}

menu {
  padding: 0
}

.max-width {
  border: 0 solid greenyellow;
  display: inline-block;
  text-align: center;
  max-width: rem(1050px);
}

.center {
  align-content: center;
  text-align: center;
}

.border {
  border: rem(1px) red solid;
}

.flex-column {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.around {
  flex-flow: row wrap;
  justify-content: space-around;
}

.flex-wrap {
  flex-flow: wrap;
  justify-content: space-around;
}

.width {
  width: 100%;
  box-sizing: border-box;
}

.vbox {
  /* previous syntax */
  display: -webkit-box;
  display: -moz-box;

  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;

  /* current syntax */
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;

  -webkit-flex-direction: column;
  -moz-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.div-menu {
  margin-top: 14px;
  width: 100%;
}

.content {
  min-height: rem(400px);
  margin: 0 0;
}

footer {
  border-top: 1px dimgrey solid;
  background-color: #ffffff;
}

.footer-div-resources, .footer-div-about {
  text-align: left;
  float: left;
  flex: 1;
}

.footer-div-resources h3, .footer-div-about h3 {
  color: #545454;
  font-size: 14px;
  text-transform: uppercase;
  text-align: left;
}

footer hr {
  position: relative;
  left: rem(-1px);
  width: 100%;
  border: 0 solid rgba(210, 183, 133, 0.59);
  border-top-width: 1px;
  margin-bottom: 18px;
}

.footer-menu {
  line-height: rem(30px);
}

.footer-menu .menu-item-first {
  margin-right: rem(50px);
}

.footer-menu span, .footer-div-copyright font {
  opacity: 0.9;
  color: #545454;
  font-size: rem(12px);
  cursor: pointer;
}

.copyright {
  font-size: rem(9px) !important;
}

.footer-menu-spacer {
  width: rem(50px);
}

.footer-div-spacer {
  width: rem(250px);
}

.footer-div-copyright {
  position: relative;
  top: rem(-7px);
  line-height: rem(31px);

  //min-width: rem(160px);
}

.footer-logo-spacer {
  height: rem(11px);
}

.footer-div-copyright .logo {
  width: rem(190px);
  height: rem(42px);
  background: url("/img/logo.png") no-repeat center center;
  background-size: 100%;
  flex: 0 0 rem(190px);
  position: relative;
  left: rem(10px);

}

.language-div {
  position: relative;
  top: rem(10px);
  width: rem(200px);
  text-align: right;
  color: white;
}

.language-block {
  position: relative;
  top: rem(10px);
  width: rem(200px);
  text-align: right;
  color: white;
  background: linear-gradient(180deg, #000000 0%, #141414 100%);
  border: rem(1px) solid #7C6D51;
  box-sizing: border-box;
  border-radius: rem(3px);
  padding: rem(10px) rem(20px);
  z-index: 9999;
}

.language-block .item {
  padding: rem(5px) rem(0px);
}

.language-block hr {
  border: 0;
  border-top: rem(1px) solid rgba(246, 243, 234, 0.25);
}

.language-text {
  white-space: nowrap;
  margin-top: rem(8px);
  color: white;
  font-size: rem(14px);
}

.language-text:hover {
  text-shadow: 0 0 rem(16px) rgba(255, 255, 255, 0.65);
}

.language-block .language-text {
  margin-top: rem(4px);
}

.footer-top-block-1 {
  width: 100%;
}

.flags_ico {
  position: relative;
  top: rem(5px);
  margin-right: rem(10px);
  width: rem(20px);
  height: rem(20px);
}

.language-block .flags_ico {
  position: relative;
  top: rem(2px);

}

.flags-spacer {
  width: 100%;
}

.flex {
  flex: 1;
}

.button {
  opacity: 0.9;
  height: rem(44px);
  border: rem(1px) solid #7C6D51;
  box-sizing: border-box;
  border-radius: rem(3px);
  background: #202020 url('/img/button-home.svg') no-repeat right (-1px) top rem(-2px);
  background-size: auto 109%;
  line-height: rem(44px);
  font-size: rem(16px);
  box-shadow: 0 0 rem(9px) rgba(255, 194, 0, 0.06);
  color: #D9B97B;
  padding: 0 rem(69px) 0 rem(24px);
  cursor: pointer;
  width: auto;
  display: inline-block;

}

.button:hover {
  color: #d9bb74;
  text-shadow: 0 0 rem(14px) rgba(255, 194, 0, 0.46);
  opacity: 1;
}

.button-back {
  height: rem(44px);
  border: rem(1px) solid #CB9834;
  box-sizing: border-box;
  box-shadow: 0 0 9px rgba(255, 194, 0, 0.06);
  border-radius: rem(3px);
  cursor: pointer;
  width: auto;
  display: inline-block;
  line-height: normal;
  font-size: rem(16px);
  color: #CB9834;
  padding: 0 rem(20px) rem(2px) rem(50px);
  background: url('/img/button-back-bar.svg') no-repeat left rem(20px) center;

}

.button-back:hover {
  color: #dda539;
  background-color: #CB983422;
}

.button-solutions {
  display: block;
  opacity: 1;
  background: rgba(217, 185, 123, 0.91) url('/img/button-solutions.svg') no-repeat right rem(-1px) top rem(-2px);
  background-size: auto 109%;
  border: rem(1px) solid #CB9834;
  box-sizing: border-box;
  border-radius: rem(3px);
  color: white;
  line-height: rem(42px);
  height: rem(44px);
  padding: 0 rem(69px) rem(0px) rem(24px);
  font-size: rem(16px);
  cursor: pointer;
  max-width: rem(300px);
  outline: none;
  text-wrap: none;
  white-space: nowrap;

}

.button-solutions:hover {
  opacity: 0.9;
}

.button-solutions2 {

  white-space: nowrap;
  background: url('/img/button-solutions.svg') no-repeat right rem(-1px) top rem(-2px);
  background-size: auto 109%;
  border: rem(1px) solid #CB9834;
  box-sizing: border-box;
  border-radius: rem(3px);
  color: #CB9834;
  padding: 0 rem(69px) 0 rem(24px);
  line-height: rem(42px);
  height: rem(44px);
  font-size: rem(16px);
  cursor: pointer;
  width: auto;
  //max-width: rem(350px);

  outline: none;
  opacity: 1;

}

.button-solutions2:hover {
  opacity: 0.9;
}

.buttons .ico {
  width: rem(43px);
  height: rem(44px);
}

.left {
  text-align: left;
  float: left;
}

.pointer {
  cursor: pointer;
}

h1 {
  font-weight: 600;
  line-height: rem(42px);
  font-size: rem(34px);
  text-transform: uppercase;
  color: #F6F3EA;
  margin-bottom: rem(50px);
}

h2, h3 {
  color: #CB9834;
  font-size: rem(20px);
  text-transform: uppercase;
  text-align: left;
  font-weight: 600;

}

.up {
  text-transform: uppercase;
}

.tooltip {

}

.check_label {
  top: rem(-2px);
  position: relative;
  width: 100%;
  border: 0;
  font-size: rem(12px);
  color: #2f2f2f;
  line-height: rem(28px);
}

.checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  margin: rem(20px) 0 0 rem(40px);

}

.checkbox + label {
  position: relative;
  padding: 0 0 0 rem(50px);
  cursor: pointer;
}

.checkbox + label:before {

  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  width: rem(40px);
  height: rem(20.5px);
  border-radius: rem(13px);
  background: #ebf3e0;
  box-shadow: inset 0 rem(2px) rem(2px) rgba(0, 0, 0, .3);
  transition: .2s;
}

.checkbox + label:after {
  content: '';
  position: absolute;
  top: rem(-2px);
  left: rem(2px);
  width: rem(18px);
  height: rem(18px);
  border-radius: rem(10px);
  background: #FFF;
  box-shadow: 0 rem(2px) rem(5px) rgba(0, 0, 0, .3);
  transition: .2s;
}

.checkbox:checked + label:before {
  background: #c7d4ac;
}

.checkbox:checked + label:after {
  left: rem(21px);
}

.checkbox:focus + label:before {
}

.center-vertical {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.flex-center-vertical {
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-loading {
  display: flex;
  //justify-content: center;
  padding: rem(100px);
  align-items: center;
  margin-bottom: rem(50px);
}

.div-loading-preloader {
  width: 100%;
  padding-bottom: rem(30px);
}

.border-zero {
  border: 0 !important;
  //border: 1px solid red;
}


.url, .text a {
  color: #c09838;
  text-decoration: underline;
}

.url:hover {
  color: #d8a349;
  text-decoration: underline;
}

.speed-animate {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.z-index-max {
  z-index: 999999;
}

.flex-right {
  display: flex;
  justify-content: flex-end !important;
}

.margin10 {
  margin: rem(10px);
}

.flex-right {
  justify-content: flex-end;
}

li {
  font-style: normal;
  margin: rem(5px) rem(15px);
  padding: 0 rem(10px) 0 rem(15px);
}

.notify {
  position: absolute;
  left: 50%;
  z-index: 99999;
}

.notify-in {
  border-radius: rem(3px);
  box-shadow: #2f2f2f 3px 3px 13px;
  position: relative;
  left: -50%;
  padding: rem(30px) rem(40px) rem(20px) rem(40px);
  background: #ffffff;
  width: rem(500px);
  color: red;
}

.notify-button {
  color: black;
  cursor: pointer;
  font-size: rem(10px);
  line-height: rem(20px);
  padding: rem(4px) rem(10px) rem(4px) rem(10px);;
  background: #e8e8e8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: -moz-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: -ms-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: -o-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: linear-gradient(to bottom, #e8e8e8, #dbdbdb);
  -webkit-border-radius: rem(28px);
  -moz-border-radius: rem(28px);
  border-radius: rem(28px);
  display: inline-block;
  margin: rem(10px) rem(6px);
}

.notify-button:hover {
  background: #e0e0e0;
  background-image: -webkit-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: -moz-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: -ms-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: -o-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: linear-gradient(to bottom, #e0e0e0, #cccccc);
}

.qr {
  display: block;
  width: rem(140px);
  padding: rem(10px);
  background: #ffffff;
}

.message-error {
  background: url('/img/warning.svg') no-repeat left center;
  padding: rem(21px) rem(40px) rem(20px) rem(40px);
  background-size: rem(34px) rem(34px);
  color: #b90000;
}

.pdf-url {
  background: url('/img/pdf.png') no-repeat left center;
  color: #c09838;
  text-decoration: none;
  padding: rem(10px) rem(10px) rem(10px) rem(30px);
  background-size: 14%;
}

.pdf-url:hover {
  color: #d8a349;
  text-decoration: underline;
}

.socials-div {
  margin-top: rem(10px);
}

.socials-div a div {
  width: rem(30px);
  height: rem(30px);
}

.socials-div a {
  border: 0px solid red;
  margin-left: rem(10px);
  opacity: 0.8;
}

.socials-div a:hover {
  opacity: 0.7;
}

.socials-div .s-twitter {
  background: url('/img/socials.png') no-repeat left top;
  background-size: 300%;
}
.socials-div .s-facebook {
  background: url('/img/socials.png') no-repeat 50% bottom;
  background-size: 300%;
}

.socials-div .s-youtube {
  background: url('/img/socials.png') no-repeat 50% bottom;
  background-size: 300%;
}

.socials-div .s-telegram {
  background: url('/img/socials.png') no-repeat 50% top;
  background-size: 300%;
}
.socials-div .s-medium {
  background: url('/img/socials.png') no-repeat left bottom;
  background-size: 300%;
}
.socials-div .s-reddit {
  background: url('/img/socials.png') no-repeat right top;
  background-size: 300%;
}
.socials-div .s-bitcointalk {
  background: url('/img/socials.png') no-repeat right bottom;
  background-size: 300%;
}

.error-404-big {
  font-size: rem(150px);
  color: #ffffff;
}

.error-404-small {
  font-size: rem(20px);
  color: #ffffff;
}

.no-decoration {
  text-decoration: none;
}
