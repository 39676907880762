@import './rem.scss';

.terms-bar {
  padding: rem(70px) rem(50px) rem(30px) rem(50px);
  background: linear-gradient(58.23deg, #262626 7.54%, #0D0D0D 99.45%);
}

.terms-bar-text {
  padding: rem(70px) rem(50px) rem(30px) rem(50px);
  background-color: white;
}

.terms-bar .text {
  position: relative;
  top: rem(-50px);
  line-height: rem(24px);
  text-align: left;
  color: #b2b2b2;
  font-size: rem(14px);
}

.terms-bar .div-text {
  flex: rem(3px);
}


.terms-bar-text .div-text h3 {
 font-size: rem(18px);
  display: block;
}

.terms-bar-text .div-text .text {
  margin-bottom: rem(50px);
  display: block;
  line-height: rem(21px);
  font-size: rem(14px);
}


.terms-bar-text .div-text .text li {
  line-height: rem(25px);
}

.terms-bar-text .div-text .text b, .terms-bar-text .div-text .text a {
  color: #1c517a;
  font-weight: 600;

}