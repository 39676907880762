@import './rem.scss';

@media screen and (min-width: 10px) {

    .solutions-blocks .item {
        flex: 1 1 100%;
    }

    .solutions-blocks .i1 {
        padding-right: 0;
    }

    .solutions-blocks .i3 {
        padding-right: 0;
    }

    .solutions-blocks .i5 {
        padding-right: 0;
    }

    .box .i2 {

        visibility: visible;
        width: 80%;

        margin-bottom: rem(30px);
        margin-left: 0;

    }

    .box .i1 {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .box .div-image {
        flex: 0;
    }

    .box .div-text {
        flex: 1;
        padding: 0;
        margin: 0;
        text-align: center;
    }

    .box .div-image {
        width: 0;
    }

    .button-solutions {
        //margin-left: rem(50px);

    }
    .button-solutions2 {
        //margin-top: rem(30px);
        //margin-left: rem(50px);
    }

    .div-image .image {
        padding: 0;
    }

}

@media screen and (min-width: 768px) {

    .solutions-blocks .item {
        flex: 1 1 40%;
    }

    .solutions-blocks .i1 {
        padding-right: rem(44px);
    }

    .solutions-blocks .i3 {
        padding-right: rem(44px);
    }

    .solutions-blocks .i5 {
        padding-right: rem(44px);
    }

    .box .i1 {
        visibility: visible;
        margin-top: rem(20px);

    }

    .box .i2 {
        visibility: hidden;
        width: 0;
        height: 0;
    }

    .box .protection {
        width: rem(392px);
        height: rem(300px);
    }

    .box .investment {
        width: rem(300px);
        height: rem(290px);
    }

    .box .reliable {
        width: rem(330px);
        height: rem(267px);
    }

    .box .alternative {
        width: rem(380px);
        height: rem(290px);
    }

    .box .gift {
        width: rem(325px);
        height: rem(199px);
    }

    .box .protect {
        width: rem(447px);
        height: rem(260px);
    }

    .box .div-image {
        flex: 1;
    }

    .box .div-text {
        flex: 1;
        text-align: left;
    }

    .box .div-image {
        width: auto;
    }

    .button-solutions {
        //margin-left: rem(100px);

    }
    .button-solutions2 {
        //margin-top: rem(30px);
        //margin-right: rem(100px);
        //margin-left: rem(100px);
    }
    .div-buttons {
        flex-basis: 100%;
    }

    .div-image .image {
        padding: rem(20px);
    }
}

@media screen and (min-width: 1060px) {

    .solutions-blocks .item {
        flex: 1 1 25%;
    }

    .solutions-blocks .i1 {
        padding-right: rem(44px);
    }

    .solutions-blocks .i2 {
        padding-right: rem(44px);
    }

    .solutions-blocks .i3 {
        padding-right: 0;
    }

    .solutions-blocks .i4 {
        padding-right: rem(44px);
    }

    .solutions-blocks .i5 {
        padding-right: rem(44px);
    }

    .solutions-blocks .i6 {
        padding-right: 0;
    }

    .box .i1 {
        visibility: visible;
        margin-top: rem(20px);

    }

    .box .i2 {
        visibility: hidden;
        width: 0;
        height: 0;
        margin-bottom: 0;
    }

    .button-solutions {

        margin-left: 0;
    }

    .button-solutions2 {
        margin-top: 0;
        margin-right: 0;
        margin-left: 0;
        flex-basis: 10%;
    }

    .div-buttons {
        flex-basis: 100%;
    }

}
