@import './rem.scss';

.home_block_main .left-block h1 {
  font-weight: 600;
  color: #D9B97B;
  text-transform: uppercase;
  text-align: left;
}

.home_block_main .left-block h3 {
  text-align: left;
  color: #D9B97B;
  text-transform: uppercase;
  font-size: rem(18px);
}

.home_block_main .left-block font {
  float: left;
  line-height: rem(24px);
  text-align: left;
  color: #b2b2b2;
  font-size: rem(14px);
}

.home_block_main {
  padding-bottom: rem(50px);
  background: url('/img/line.svg') no-repeat;
}

.home-main-block .div-left {
  flex: 1;
  background: url('/img/line.svg') right rem(-300px) top rem(-98px) no-repeat;
}

.home-main-block .div-right {
  flex: 1;
  background: url('/img/line.svg') no-repeat;
}

.home-main-block .div-center {
  max-width: rem(1050px);
  width: 100%;
}

.home-detail-block {
  margin-bottom: 0;
}

.home-purity-block .div-center {
  box-sizing: border-box;
  display: inline-block;
  width: 100%;
  background: linear-gradient(180deg, #141414 0%, #121212 100%);
  border-radius: rem(3px);
}

.home-detail-block .border-div {
  margin-top: rem(50px);
  width: 100%;
  border: rem(1px) solid #D9B97B;
  box-sizing: border-box;
  border-radius: rem(3px);
  padding: rem(30px) 0;

}

.home-detail-block .border-div .item, .home-purity-block .item {
  display: block;
  padding: rem(20px) rem(10px);
  box-sizing: border-box;
}

.home-detail-block .border-div .separator {
  border: 0;
  border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
}

.home-detail-block .border-div b {
  color: #f6f3ea;

}

.home-detail-block .border-div font {
  color: #b2b2b2;
  font-size: rem(16px);
  font-weight: 400;
}

.home-detail-block .border-div .spacer {
  height: rem(25px);
}

.home-adv-gradient {
  background: linear-gradient(180deg, #1B1B1B 0%, #202020 34.36%);
}

.home-adv {
  margin-top: rem(50px);
}

.home-adv b {
  color: #f6f3ea;
  text-align: left;
  font-size: rem(14px);
  text-transform: uppercase;
  font-weight: 600;
}

.home-adv font {
  color: rgba(246, 243, 234, 0.87);
  opacity: 0.8;
  font-size: rem(14px);
  text-align: left;
  line-height: rem(22px);
}

.home-adv .spacer {
  height: rem(20px);
}

.home-adv div {
  text-align: left;
}

.home-adv div .image {
  opacity: 0.9;
  width: rem(62px);
  height: rem(62px);
  background-repeat: no-repeat;
  background-size: 100% 100%;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.home-adv div .image:hover {
  opacity: 1;
  fill: #20ff09;
}

.home-adv div .i1 {
  background-image: url('/img/adv-img-1.svg')
}

.home-adv div .i2 {
  background-image: url('/img/adv-img-2.svg')
}

.home-adv div .i3 {
  background-image: url('/img/adv-img-3.svg')
}

.home-adv div .i4 {
  background-image: url('/img/adv-img-4.svg')
}

.home-adv div .i5 {
  background-image: url('/img/adv-img-5.svg')
}

.home-adv div .i6 {
  background-image: url('/img/adv-img-6.svg')
}

.home-adv div .data {
  font-weight: 500;
  font-size: rem(14px);
  flex: 1;
  margin-left: rem(35px);
  margin-top: rem(2px);
}

.home-adv div .spacer {
  width: rem(50px);
  height: rem(15px);
}

.home-adv .spacer2 {
  height: rem(50px);
  display: block;
}

.home-bar-strategy {
  padding: rem(100px) 0;
  background: #141414;
  -webkit-transform: skewY(-3.51deg);
  -moz-transform: skewY(-3.51deg);
  -ms-transform: skewY(-3.51deg);
  -o-transform: skewY(-3.51deg);
  transform: skewY(-3.51deg);
}

.home-strategy > * {
  -webkit-transform: skewY(3.51deg);
  -moz-transform: skewY(3.51deg);
  -ms-transform: skewY(3.51deg);
  -o-transform: skewY(3.51deg);
  transform: skewY(3.51deg);
}

.home-strategy h3 {
  margin-bottom: rem(50px);
}

.home-strategy .text-block font {
  flex: 1;
  float: left;
  font-weight: 400;
  color: #b2b2b2;
  line-height: rem(23px);
  font-size: rem(14px);
  text-align: left;
}

.home-strategy .image {
  border: 0;
  margin: 0;
  padding: 0;
  width: rem(425px);
  height: rem(285px);
}

.home-strategy .spacer {
  width: rem(50px);
}

.home-strategy .image-block {
  display: inline-block;
  margin-top: 50px;
}

.home-news-bar {
  margin-top: rem(-200px);
  //padding: rem(50px) rem(50px) 0 rem(50px);
  background: linear-gradient(304.43deg, #233062 17.76%, #18203F 49.62%, #0C0F16 93.64%);
}

.div-preadvisors {
  background: #242424;
  padding-top: rem(80px);
  padding-bottom: rem(80px) !important;
}

.advisor-h2 {
  color: #CB9834;
  font-size: rem(20px);
  text-transform: uppercase;
  font-weight: 600;
  text-align: center !important;
  margin-bottom: rem(20px);
}

.advisor {
  display: block;
  width: rem(180px);
  height: rem(200px);
  color: #b2b2b2;
}

.advisor .preimg {
  margin-top: rem(10px);
  border-radius: rem(3px);
  overflow: hidden;
  width: rem(180px);
  height: rem(200px);
  display: block;
}

.advisor .img {
  display: block;
  width: rem(180px);
  height: rem(200px);
  object-fit: cover;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.advisor .img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.advisor .img.tonetto {
  background: url("/img/tonetto.png");
  background-size: cover;
}

.advisor .img.scott {
  background: url("/img/scott.png");
  background-size: cover;
}

.advisor .adv-name {
  display: block;
  margin: rem(12px) rem(1px) rem(6px) rem(1px);
  color: #b2b2b2;
}

.advisor .url a {
  font-size: rem(12px);
  color: #d9b164;
}

.div-prenews {
  margin-top: rem(280px);
  margin-bottom: rem(30px);
}

.home-news {
  margin-top: rem(30px);
}

.home-news .item {
  padding-right: rem(20px);
  box-sizing: border-box;
  margin-top: rem(30px);

}

.pre_image {
  -webkit-transform: translateZ(0);
  //border: rem(1px) solid #CB9834;
  border-radius: rem(3px);
  z-index: 1;
  display: block;
  background: url('/img/news-default.jpg') center center no-repeat;
  background-size: cover;
  width: 100%;
  height: rem(200px);
  overflow: hidden;
  margin-right: rem(24px);
}

.home-news .item .image {
  box-sizing: border-box;
  border-radius: rem(3px);
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  background-position: center;
  background-size: cover;

  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -o-transform: scale(1.01);
}

.home-news .item .image:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -o-transform: scale(1.1);
}

.home-news .text {
  margin-top: rem(10px);
  text-align: left;
  // background: rgba(246, 243, 234, 0.22);
  border-radius: rem(3px);
  padding: rem(20px);
  height: calc(100% - 240px);
}

.home-news .item .text b {

  line-height: rem(23px);
  font-size: rem(16px);
  text-transform: uppercase;
  color: #F6F3EA;
  margin-bottom: rem(100px);
}

.home-news .item .text div {
  text-align: justify;
  font-weight: 400;
  box-sizing: border-box;

  line-height: rem(18px);
  font-size: rem(13px);
  color: rgba(246, 243, 234, 0.6);
}

.home-news .item .text .url {
  padding: rem(1px) rem(30px) rem(1px) 0;
  display: inline-block;
  margin-top: rem(15px);
  color: #d09c35;
  cursor: pointer;
  font-size: rem(14px);
  background: url('/img/url.svg') no-repeat right;
}

.home-news .item .text .url:hover {
  color: #d6a137;
  text-decoration: underline;
}

.home-news .item .time span {
  display: block;
  text-align: left;
  font-size: rem(10px);
  margin-top: rem(10px);
  margin-bottom: rem(10px);
  color: white;
}

.adv-item {
  flex-flow: wrap;
  margin-bottom: rem(50px);
  box-sizing: border-box;
}

.key-anim {
  animation-name: key-anim1;
  animation-duration: 3s;

  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.key-anim:hover {

}

.strategy-image-text-div {
  height: rem(285px);
  color: #d9b164;
  padding: 0;
  font-weight: 400;
  font-size: rem(14px);
  background: url('/img/strategy-image.svg') no-repeat;
  background-size: 100% 100%;
  margin-bottom: rem(20px);

}

.strategy-image-text-div h4 {
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  padding-top: rem(24px);
  font-weight: bold;
  font-size: rem(16px);
}

.strategy-image-text-div .reduction {
  margin-top: rem(23px);
  font-weight: 400;
  color: #6b5a38;
  line-height: rem(22px);
  font-size: rem(14px);
}

.strategy-image-text-div .bid-ask {
  font-weight: 400;
  margin-top: rem(28px);
  font-size: rem(13px);
}

.strategy-image-text-div .buy-sell {
  padding: rem(94px) rem(70px) 0 rem(70px);
}

.purity {
  padding: rem(30px);
  color: #b2b2b2;
  font-size: rem(14px);
}

.purity .text-big {
  color: #d9b164;
  font-weight: 600;

  height: rem(60px);
  line-height: rem(68px);
}

.purity a {
  text-decoration: underline;
  color: #b2b2b2;
}

.purity a:hover {
  color: #eeeeee;
}

.purity .text-big.logo {
  display: inline-block;
  height: rem(60px);
  background: url('/img/gold3.png') left center no-repeat;
  padding-left: rem(46px);
  background-size: 22%;
  line-height: rem(66px);
}

.purity .text-small {
  color: #ffffff;
  font-weight: 400;
  font-size: rem(14px);
  position: relative;
  top: rem(-24px);
  margin-left: rem(5px);
}

.gr {
  //font-size: rem(10px);
  color: #999999;
}

.substribe-div input {
  border: rem(1px) solid #5e5e5e;
  box-sizing: border-box;
  border-radius: rem(3px);
  color: #b2b2b2;
  font-size: rem(14px);
  height: rem(44px);
  padding: 0 rem(20px);
  width: 100%;
  outline: none;
  background: transparent;
}

.substribe-div legend {
  display: block;
  font-size: rem(14px);
  color: #b2b2b2;
  padding-left: rem(1px);
  margin-bottom: rem(6px);
  height: rem(15px);
  line-height: rem(15px);
}

.button-subscribe {
  display: block;
  opacity: 0.9;
  background: #202020 url('/img/loading-bubbles-white.svg') no-repeat -1000px -1000px;
  border: rem(1px) solid #CB9834;
  box-shadow: 0 0 0.64286rem rgba(255, 194, 0, 0.06);
  color: #D9B97B;
  box-sizing: border-box;
  border-radius: rem(3px);
  line-height: rem(42px);
  height: rem(44px);
  padding: 0 rem(24px) rem(0px) rem(24px);
  font-size: rem(16px);
  cursor: pointer;
  outline: none;
  text-wrap: none;
  white-space: nowrap;
  margin-left: rem(20px);
}

.button-subscribe-loading {
  box-shadow: 0 0 0.64286rem rgba(255, 194, 0, 0.06);
  opacity: 1;
  display: block;
  background: #202020 url('/img/loading-bubbles-white.svg') no-repeat center center;
  border: rem(1px) solid #CB9834;
  box-sizing: border-box;
  border-radius: rem(3px);
  color: rgba(217, 185, 123, 0);
  line-height: rem(42px);
  height: rem(44px);
  padding: 0 rem(24px) rem(0px) rem(24px);
  font-size: rem(16px);
  cursor: pointer;
  outline: none;
  text-wrap: none;
  white-space: nowrap;
  margin-left: rem(20px);
}

.button-subscribe:hover {
  opacity: 1;
  color: #d9bb74;
  text-shadow: 0 0 rem(14px) rgba(255, 194, 0, 0.46);
}

.subscribe-success {
  padding: rem(0px) 0;
  color: #b2b2b2;
}

.subscribe-error {
  padding: rem(10px) 0;
  color: red;
}

.subscribe-block {
  height: rem(135px);
}

.envelope {
  padding: rem(20px);
  display: block;
  width: rem(140px);
  height: rem(100px);
  background: url('/img/envelope.svg') no-repeat center center;
  background-size: 98%;

}

.video-block {
  //border: 1px solid red;
  //height: rem(400px);
}

.react-player {
  width: 100% !important;
  //height: 100% !important;
  height: rem(549px) !important;
}


