@import './rem.scss';

@media screen and (min-width: 10px) {

  .gtfee .spacer {
    height: rem(40px);
  }
  .gtfee .footer-block h3 {
    font-size: rem(20px);
  }

}

@media screen and (min-width: 768px) {

  .gtfee .spacer {
    height: rem(70px);
  }

  .gtfee .footer-block h3 {
    font-size: rem(26px);
  }

}
