@import './rem.scss';

.dev-bar {
    padding: rem(70px) rem(50px) rem(100px) rem(50px);
    background: linear-gradient(283.74deg, #233062 17.76%, #18203F 49.62%, #0C0F16 93.64%);
    color: #b2b2b2;
}

.dev-bar .text {
    color: #b2b2b2;
    font-size: rem(14px);
    line-height: rem(24px);
}

.dev-bar2 {
    padding: rem(70px) rem(50px) rem(100px) rem(50px);
    //background: linear-gradient(283.74deg, #233062 17.76%, #18203F 49.62%, #0C0F16 93.64%);
    background: #F6F3EA;
}

.dev-bar2 .text {
    font-size: rem(13px);
    //padding: rem(22px);
    //border: 1px solid #cccccc;
    line-height: rem(22px);
    //background: #ffffff88;
    border-radius: rem(5px);
}

.dev-bar2 .text li {
    line-height: rem(15px);
}

.dev-bar2 b {
    background: #cccccc55;
    border: 1px dotted #cccccc99;
    border-radius: rem(20px);
    padding: rem(5px) rem(10px) rem(3px) rem(10px);
}
.dev-bar2 a {
    color: #CB9834;
}
.dev-bar2 a:hover {
    color: #CB9834;
}