@import './rem.scss';

.solutions-bar {
    padding: rem(50px) rem(50px);
    text-align: left;
    background: linear-gradient(304.39deg, #233062 17.76%, #18203F 49.62%, #0C0F16 93.64%);
}

.solutions-bar .text {
    line-height: rem(24px);
    text-align: left;
    color: #b2b2b2;
    font-size: rem(14px);
}

.main-max-width {
    max-width: rem(778px);
}

.solutions-blocks {
    position: relative;
    padding: rem(40px) 0 0 0;
}

.solutions-blocks .item {
    border: 0 salmon solid;
}

.solutions-blocks .item .out {
    margin-bottom: rem(30px);
    text-align: left;
    box-sizing: border-box;
    height: rem(90px);
    border: rem(1px) solid #E2B968;
    border-radius: rem(3px);
    line-height: rem(20px);
    font-size: rem(14px);
    color: #E2B968;
    font-weight: 400;
    background: url('/img/right.svg') no-repeat right rem(15px) center;

    display: flex;
    align-items: center;
    justify-content: center;
}

.solutions-blocks .item .out:hover {
    box-shadow: 0 0 rem(4px) rgba(255, 194, 0, 0.46);
    text-shadow: 0 0 rem(14px) rgba(255, 194, 0, 0.46);
}

.solutions-blocks .item .out div {
    padding: rem(22px) rem(46px) rem(22px) rem(30px);
}

.solutions-blocks .item a {
    color: #E2B968;
    text-decoration: none;
}

.protection-bar {
    background: #F6F3EA;
    padding: rem(0px) rem(50px);
    border: 0;
    border-bottom: rem(1px) solid rgba(224, 194, 141, 0.64);
}

.solutions .box {
    padding: rem(60px) 0;
}

.solutions h2 {
    font-weight: 600;
    line-height: rem(29px);
    font-size: rem(20px);
    text-transform: uppercase;
    color: #CB9834;
}

.solutions .div-text .text {
    line-height: rem(22px);
    font-size: rem(15px);
    color: #2F2F2F;
}

.solutions .image {
    box-sizing: border-box;
    border-radius: rem(3px);
    margin-left: rem(30px);
}


