@import './rem.scss';

.support-bar {
  padding: rem(70px) rem(50px) rem(100px) rem(50px);
  background: linear-gradient(58.23deg, #262626 7.54%, #0D0D0D 99.45%);
}

.support-bar .text {
  max-width: rem(600px);
  line-height: rem(24px);
  text-align: left;
  color: #b2b2b2;
  font-size: rem(14px);
}

.support-bar .div-text {
  flex: 1 1 auto
}

.support-bar .image {
  margin-top: rem(20px);
  background: url("/img/heard.svg") no-repeat center center;
  background-size: 100%;
  width: rem(200px);
  height: rem(200px);
  border: 0px sienna solid;
}

.support-bar .div-image {
 border: 0px sienna solid;
  flex: 1 1 rem(200px);
}

.support-bar-form {
  padding: rem(70px) rem(50px) rem(100px) rem(50px);
  background: #F6F3EA;
}

.support-bar-form .form {
  flex: 1 1 75%;
}

.support-bar-form .preform {
  max-width: 70%;
}

.support-bar-form h1 {
  color: #202020;
}

.support-bar-form input, .support-bar-form textarea {
  border: rem(1px) solid #348313;
  box-sizing: border-box;
  border-radius: rem(3px);
  color: #348313;
  font-size: rem(14px);
  height: rem(44px);
  padding: 0 rem(20px);
  width: 100%;
  outline: none;
  margin-bottom: rem(20px);
  background: #ffffff;

}

.support-bar-form input::placeholder, .support-bar-form textarea::placeholder {
  color: #4D4C4B99;
}

.support-bar-form textarea {
  line-height: rem(22px);
  padding: rem(15px) rem(20px);
  height: rem(200px);
}

.support-bar-form legend {
  display: block;
  font-size: rem(12px);
  color: #000000;
  opacity: 0.8;
  padding-left: rem(1px);
  margin-bottom: rem(6px);
}

.support-bar-form .button {
  outline: none;
  font-weight: 400;
  border: rem(1px) solid #c3992c;
  border-radius: rem(3px);
  height: rem(42px);
  line-height: rem(40px);
  padding: rem(0px) rem(40px) rem(0px) rem(40px);
  color: #F6F3EA;
  font-size: rem(16px);
  text-transform: capitalize;
  cursor: pointer;
  background: #c3992c;
  width: auto;
  box-sizing: border-box;
}

.support-bar-form .button-loading {
  outline: none;
  font-weight: 400;
  border: rem(1px) solid #c3992c;
  border-radius: rem(3px);
  height: rem(42px);
  line-height: rem(40px);
  padding: rem(0px) rem(40px) rem(0px) rem(40px);
  color: #c3992c00;
  font-size: rem(16px);
  text-transform: capitalize;
  width: auto;
  background: #c3992c url('/img/loading-bubbles-white.svg') no-repeat center center;
}

.support-bar-form .button:hover {
  background: #d7a831;
  color: #F6F3EA;
}

.support-bar-form .error {
  border: rem(1px) solid red;
  background-color: #fff1ea;
}

.support-bar-form .address {
  color: #2F2F2F;
  flex: 1 1 35%;
  line-height: rem(24px);
  font-size: rem(14px);
}

.support-bar-form h3 {
  margin-bottom: rem(10px);
}

.chat .text {
  font-size: rem(14px);
  line-height: rem(24px);
}

.support-div {
  width: 100%;
  display: flex;
  flex-direction: row;
}