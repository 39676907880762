@import './rem.scss';

.features-bar {
  background: linear-gradient(283.74deg, #233062 17.76%, #18203F 49.62%, #0C0F16 93.64%);
}
.features {
  padding-top: rem(20px);
}

.features .title {

  font-size: rem(20px);
  line-height: rem(29px);
  text-transform: uppercase;
  color: #D9B97B;
  margin-top: rem(22px);

}

.features .text {
  max-width: rem(800px);
  text-align: left;
  font-size: rem(14px);
  line-height: rem(24px);
  color: #b2b2b2;
}

.features .block {
  margin-bottom: rem(60px);
}

.features .block .flex-row {
  margin-bottom: rem(10px);
}

.features .img {
  margin-left: rem(-8px);
  margin-right: rem(20px);
  height: rem(67px);
  width: rem(67px);
  border: 0;
}

.features .i1 {
  background: url('/img/feat-img-1.svg') no-repeat center;
}

.features .i2 {
  background: url('/img/feat-img-2.svg') no-repeat center;
}

.features .i3 {
  background: url('/img/feat-img-3.svg') no-repeat center;
}

.features .i4 {
  background: url('/img/feat-img-4.svg') no-repeat center;
}

.features .i5 {
  background: url('/img/feat-img-5.svg') no-repeat center;
}

.features .i6 {
  background: url('/img/feat-img-6.svg') no-repeat center;
}

