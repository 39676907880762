@import './rem.scss';

@media screen and (min-width: 10px) {
    .fee-bar .div-image {
        width: 0;
    }

    .fee-bar .gold {
        width: 0;
        margin: rem(134px) rem(30px);
    }

    .fee-bar .logo-bar {
        background: url('/img/gold2.svg') no-repeat center;
        background-size: 50%;
    }

}

@media screen and (min-width: 768px) {
    .fee-bar .div-image {
        width: rem(300px);
    }

    .fee-bar .gold {
        width: rem(166px);
        margin: rem(134px) rem(30px);
    }

    .fee-bar .logo-bar {
        background: none;
    }
}

@media screen and (max-width: 1000px) {
    table.table-fee thead {
        display: none;
    }
    table.table-fee tr {
        display: block;
        margin-bottom: 1rem;
        //border-bottom: 2px solid #e8e9eb;
    }
    table.table-fee td {
        border-right: 0;
        display: flex;
        text-align: right;
        //line-height: rem(52px);
        justify-content: space-between;
    }
    table.table-fee td:before {
        content: attr(aria-label);
        float: left;
        font-weight: bold;

    }

    table.table-fee .resolution-th:before {
        display: none;
    }
    table.table-fee .resolution-th {
        text-align: left;
        white-space: nowrap;
        text-transform: uppercase;
        background-color: #000000;
        vertical-align: middle;
        height: rem(50px);
        line-height: rem(50px);
        color: white;
        border-right: 0;

        font-size: rem(11px);
    }
}
