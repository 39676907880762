@import './rem.scss';

.audits-bar {
  padding: rem(70px) rem(50px) rem(100px) rem(50px);
  background: linear-gradient(283.74deg, #233062 17.76%, #18203F 49.62%, #0C0F16 93.64%);
}

.audits-bar2 .text {
  font-size: rem(14px);
  line-height: rem(24px);
  text-align: left;

}

.audits-bar .div-text {
  flex: rem(3px);
}

.audits-bar2 {
  padding: rem(70px) rem(50px) rem(100px) rem(50px);
  //background: linear-gradient(58.23deg, #fffed7 7.54%, #f7f5f9 99.45%);
  background: #F6F3EA;

}

.audits-bar2 h1 {
  color: #202020;
  margin-bottom: rem(15px);
}

.audits-bar2 .audit {
  border-radius: rem(10px);
  padding: rem(110px) rem(20px) rem(30px) rem(20px);
  text-align: center;
  border: 1px dotted #CB9834;
  background: #ffffff url('/img/bull.png') no-repeat center top rem(20px);
}

.audits-bar2 .audit a {
  color: #CB9834;
}

.audits-bar2 .audit a:hover {
  color: #b98a30;

}

.audits-bar2 .stat div span {
  padding: rem(4px);
}

.audits-bar2 .stat {
  max-width: rem(500px);
}

.audits-bar2 h4 {
  color: #CB9834;
}

.audit-contract-preview {
  margin-bottom: rem(30px);
  width: rem(240px);
  height: rem(220px);
  background-size: 86%;
  border: 1px dotted #CB9834;
  border-radius: rem(10px);
}

.audit-contract-preview {
  width: rem(220px);
  height: rem(200px);
  border: 1px dotted #CB9834;
  border-radius: rem(10px);
}

.audit-contract-preview.chainsecurity {
  background: #ffffff url('/img/contract-audit-preview.png') no-repeat center center;
  background-size: 86%;
  margin-right: rem(50px);
}

.audit-contract-preview.anchain {
  background: #ffffff url('/img/contract-audit-preview-anchain.png') no-repeat center center;
  background-size: 95%;
  margin-right: rem(50px);

}

.audits-blocks .text {
  padding: rem(50px) rem(50px) rem(50px) 0;
}
