@import './rem.scss';

.market-bar {
  background-color: white;
}

.market-bar h1 {
  color: #2F2F2F;
  margin-bottom: rem(15px);
}

.market-protection-bar h2, .market-partners-bar h2 {
  margin-bottom: rem(30px) !important;
}

.market-bar h4 {
  font-weight: 600;
  text-transform: uppercase;
  color: #2F2F2F;
  font-size: rem(18px);
  margin-bottom: rem(15px);

}

.market-bar .text {
  margin-top: rem(25px);
  line-height: rem(24px);
  max-width: rem(850px);
  font-size: rem(14px);
  color: #2F2F2F;
}

.market-div {
  display: block;
  box-sizing: border-box;
  margin-bottom: rem(40px);
  background: linear-gradient(317.11deg, #007112 1.3%, #3B801B 40.25%, #296919 94.07%);
  border-radius: rem(3px);
}

.market-div .input {
  background: rgba(246, 243, 234, 0.18);
  border: rem(1px) solid rgba(246, 243, 234, 0.3);
  box-sizing: border-box;
  border-radius: rem(4px);
  height: rem(47px);
}

.market-div .input input {
  min-width: rem(30px);
  font-size: rem(17px);
  color: #f6f3ea;
  font-weight: 400;
  height: rem(45px);
  border: 0;
  box-sizing: border-box;
  background-color: transparent;
  outline: none;
  padding: rem(1.5px) rem(20px) 0 rem(20px);
  flex: 1 1 auto;
}

.market-select2 {
  cursor: pointer;
  background-color: #C6D4B7;
  width: rem(82px);
  //border-radius: rem(3px);
  height: rem(27px);
  overflow: hidden;
  margin: 0;
}

.market-select2:hover {
  background-color: #78e262;
}

.market-select {
  cursor: pointer;
  background-color: #C6D4B7;
  width: rem(82px);
  border-radius: rem(3px);
  overflow: hidden;
  flex: 0 0 rem(82px);
}

.market-select.gold {
  background: #C6D4B7 url('/img/symbol-gold2.png') no-repeat center;
  background-size: 64%;
}

.market-select.btc {
  background: #C6D4B7 url('/img/symbol-btc2.png') no-repeat center;
  background-size: 64%;
}

.market-select.eth {
  background: #C6D4B7 url('/img/symbol-eth2.png') no-repeat center;
  background-size: 64%;
}

.market-select2.gold {
  background: #C6D4B7 url('/img/symbol-gold2.png') no-repeat 15px center;
  background-size: 54%;
}

.market-select2.btc {
  background: #C6D4B7 url('/img/symbol-btc2.png') no-repeat 11px center;
  background-size: 54%;
}

.market-select2.eth {
  background: #C6D4B7 url('/img/symbol-eth2.png') no-repeat 11px center;
  background-size: 54%;

}

.img-change {
  margin-left: rem(5px);
  margin-right: rem(5px);
  margin-top: rem(13px);
  width: rem(24px);
  height: rem(68px);
  cursor: pointer;
}

.button-exchange {
  white-space: nowrap;
  outline: none;
  font-weight: 400;
  border: rem(1px) solid #c3992c;
  border-radius: rem(3px);
  line-height: rem(42px);
  padding: rem(0px) rem(70px) rem(2px) rem(40px);
  color: #F6F3EA;
  font-size: rem(16px);
  text-transform: capitalize;
  cursor: pointer;
  background: #c3992c url('/img/right-white.svg') no-repeat right rem(20px) center;
}

.button-exchange-loading {
  outline: none;
  font-weight: 400;
  border: rem(1px) solid #c3992c;
  border-radius: rem(3px);
  line-height: rem(42px);
  padding: rem(0px) rem(70px) rem(2px) rem(40px);
  color: #F6F3EA;
  font-size: rem(16px);
  text-transform: capitalize;
  background: #c3992c url('/img/loading-bubbles-dark.svg') no-repeat right rem(20px) center;
}

.button-exchange:hover {
  background: #d7a831 url('/img/right-white.svg') no-repeat right rem(20px) center;
}

.button-exchange.false {
  cursor: auto;
  opacity: 0.5;
}

.button-exchange.false:hover {
  cursor: auto;
  opacity: 0.5;
  background: #c3992c url('/img/right-white.svg') no-repeat right rem(20px) center;
}

.button-exchange.true:hover {
  background: #d7a831 url('/img/right-white.svg') no-repeat right rem(20px) center;
}

.button-exchange.true {
  cursor: pointer;
  opacity: 1;
}

.button-exchange-v2 {
  outline: none;
  font-weight: 400;
  border: rem(1px) solid #c3992c;
  border-radius: rem(3px);
  line-height: rem(42px);
  padding: rem(0px) rem(40px) rem(2px) rem(40px);
  color: #F6F3EA;
  font-size: rem(16px);
  text-transform: capitalize;
  cursor: pointer;
  background: #c3992c no-repeat right rem(20px) center;
}

.button-exchange-v2:hover {
  background: #d2a52f;
}

.market-div .legend {
  display: block;
  font-size: rem(12px);
  color: #F6F3EA;
  opacity: 0.4;
  margin-bottom: rem(10px);
}

.market-div .rate {
  font-weight: 400;
  margin-top: rem(20px);
  margin-left: rem(1px);
  color: #F6F3EA;
  font-size: rem(12px);
}

.market-div-additional {
  position: relative;
  box-sizing: border-box;
  top: rem(-40px);
  background: #e7e5c7;
  border-radius: rem(3px);
}

.market-div-additional .form input {
  border: rem(1px) solid #348313;
  box-sizing: border-box;
  border-radius: rem(3px);
  color: #348313;
  font-size: rem(14px);
  height: rem(44px);
  padding: 0 rem(20px);
  width: 100%;
  outline: none;
}


.market-div-additional .legend {
  display: block;
  font-size: rem(12px);
  color: #2F2F2F;
  padding-left: rem(1px);
  margin-bottom: rem(5px);
  height: rem(15px);
  line-height: rem(15px);
}

.bold {
  font-weight: 600;
  color: #000000;
}

.market-select-spacer {
  height: rem(5px);
}

.market-div .deselector {
  position: absolute;
  border: 0 solid red;
  width: rem(82px);
  display: block;
  margin-top: 0;
  padding-top: rem(47px);
  z-index: 999;
  //background-color: #1c517a;
}

.market-div .preselector {
  background-color: #C6D4B7;
  border-radius: rem(3px);
  overflow: hidden;
  box-shadow: 1px 1px 3px #20202088;
}

.market-div .market-select:hover, .market-select2:hover {
  background-color: #F6F3EA;
}

.z-index1 {
  z-index: 1;
}

.z-index2 {
  z-index: 2;
}

.confirm_privacy {
  font-size: rem(12px);
  margin-left: rem(5px);
  color: #2f2f2f;
  position: relative;
  top: rem(-5px);
}

.confirm_privacy:hover {
  text-underline: #0C0F16;
}

.align-left {
  text-align: left;
}

.market-protection-bar {
  background-color: #F6F3EA;
  padding: rem(60px) rem(50px) rem(0px) rem(50px);

}

.market-partners-bar {
  background-color: #F6F3EA;
  padding: 0 rem(50px) rem(50px) rem(50px);
  position: relative;
}

.market-protection-bar .text, .market-partners-bar .text {
  font-size: rem(14px);
  line-height: rem(24px);
}

.partners-div {
  margin-top: rem(50px);
  margin-bottom: rem(20px);
}

.partners-div .text {
  font-size: rem(14px);
  line-height: rem(24px);
  padding-right: rem(40px);
  color: #2F2F2F;
}

.partners-div .url a {
  cursor: pointer;
  font-size: rem(14px);
  line-height: rem(22px);
  color: #CB9834;
  text-decoration: none;
}

.partners-div .url a:hover {
  cursor: pointer;
  font-size: rem(14px);
  line-height: rem(22px);
  color: #d7a137;
  text-decoration: underline;
}

.partners-div .logo {
  margin-top: rem(3px);
  //border: 1px solid red;
  flex: 1 0 rem(160px);
}

.market-div-additional .form .error {
  /*//border: 1px solid #348313;*/
  /*border: 1px solid red !important;*/
  border: rem(1px) solid red;
  background-color: #fff1ea;

}

.market-div-additional .form {
  padding-bottom: rem(30px);
}

.market-div-additional .form-button div {
  padding: 0;
  margin: rem(20px) 0 0 0;
}

.div_error {
  padding: rem(30px);
  color: #e3b897;
  font-size: rem(18px);
}

.div_loading {
  padding: rem(30px);
  color: #e4e6e9;
  font-size: rem(14px);
}

.order-bar {
  //background: white;
  width: 100%;
}

.order-main-bar {
  //background: #1c517a;
  color: white;
  font-weight: 300;
}

.order-bar .table {
  //background-color: #eeeeee;
  padding: rem(0px);
  border: 0px solid red;
}

.order-bar .table .tbodyth {
  text-align: left;
  padding: rem(24px) rem(40px);
  color: white;
  //background-color: #2c7a9b;
  font-size: rem(16px);
  border-radius: rem(5px) rem(5px) 0 0;
}

.order-bar .tbodyth-payments {
  text-align: left;
  padding: rem(14px) rem(40px) rem(14px) rem(50px);
  color: white;
  background-color: #C6D4B7;
  font-size: rem(14px);

}

.order-bar .in {
  background: #C6D4B7 url('/img/down.svg') no-repeat left rem(18px) center;
  color: #365634;
}

.order-bar .out {
  background: #C6D4B7 url('/img/up.svg') no-repeat left rem(18px) center;
  color: #365634;
}

//.order-bar .table .tbody div div {
//  display: flex;
//  flex: 1;
//  padding: rem(15px) rem(0px);
//  //border-bottom: 1px dotted #886b5a38;
//  align-items: center;
//  vertical-align: center;
//  //border: 1px solid red;
//}
//
//.order-bar .table .tbody-payment div div {
//  padding: rem(15px) rem(14px);
//  //border-bottom: 1px dotted #886b5a38;
//  align-items: center;
//  justify-content: left;
//
//
//}

.order-bar .status {
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: rem(10px);
  font-weight: 100;
  padding: rem(6px) rem(10px);
  text-shadow: rem(1px) rem(1px) rem(20px) #000000;
  text-decoration: none;
  margin-left: rem(8px);
  display: inline-block;
}

.order-bar .status.status_1, .status.status_0 {
  background-color: #3D94F6;
}

.order-bar .status.status_2, .order-bar .status.status_4 {
  background-color: #1baf16;
}

.order-bar .status.status_10, .order-bar .status.status_20, .order-bar .status.status_30, .order-bar .status.status_40 {
  background-color: #a39ea4;
}

.market-order-input {
  //margin-top: rem(10px);

  border: rem(1px) solid #348313;
  box-sizing: border-box;
  border-radius: rem(3px);
  color: #348313;
  font-size: rem(12px);
  height: rem(44px);
  padding: 0 rem(20px);
  outline: none;

  background: white;
  display: inline-block;
  line-height: rem(44px);
  width: 100% !important;
}

.tbody-payment {
  padding: rem(10px) rem(5px);
  line-height: rem(10px);
}

.tbody-payment div {
  padding: rem(3px) 0;
  text-align: left;
}

.tbody-payment div div {
  padding-right: rem(6px);
  text-align: left;
}

.tbody-payment .tr {
  padding: rem(6px) 0;
  border-bottom: 1px solid #34831322;
}

.market-order-form .small {
  font-size: rem(11px);
  color: #2f2f2f;
}

.market-order-form .b1 {
  flex: 1 1 auto;
  text-align: left;

}

.market-order-form .b2 {
  flex: 0 0 rem(80px);
  text-align: right;
}

.market-order-form .b3 {
  flex: 0 0 rem(90px);
  text-align: right;
}

.market-order-form .b4 {
  flex: 0 0 rem(100px);
  text-align: right;
}

.market-order-form .b5 {
  flex: 0 0 rem(50px);
  text-align: right;
}

.margin-right {
  margin-right: auto;
}

.margin-left {
  margin-left: auto;
}

.right {
  flex-grow: 1;
  justify-content: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-4 {
  flex-grow: 4;
}

.true-rate {
  border-radius: rem(24px);
  box-sizing: border-box;
  background: #C6D4B7 url('/img/true.svg') no-repeat center center;
  background-size: 50%;
  width: rem(24px);
  height: rem(24px);
  display: inline-block;
}

.inputflexer {
  display: flex;
  justify-content: space-between;
}

.inputflexer div, .inputflexer span {
  //border: 1px solid red;
}

.graph {
  width: 100%;
  min-width: rem(400px);
}

.graph-border {
  border: 1px solid #cccccc;
}

.graph-button-area {
  width: 100%;
  text-align: center;
}

.graph-price table tr td, .graph-price table tr th {
  padding: rem(10px) rem(10px);
  border-bottom: 1px dotted #cccccc;
}

.sn {
  color: #666666;
  font-size: rem(9px);
}

.graph-button {
  cursor: pointer;
  font-size: rem(10px);
  line-height: rem(20px);
  padding: rem(0px) rem(10px) rem(0px) rem(10px);;
  background: #e8e8e8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: -moz-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: -ms-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: -o-linear-gradient(top, #e8e8e8, #dbdbdb);
  background-image: linear-gradient(to bottom, #e8e8e8, #dbdbdb);
  -webkit-border-radius: rem(28px);
  -moz-border-radius: rem(28px);
  border-radius: rem(28px);
  display: inline-block;
  margin: rem(10px) rem(6px);
}

.graph-button:hover {
  background: #e0e0e0;
  background-image: -webkit-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: -moz-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: -ms-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: -o-linear-gradient(top, #e0e0e0, #cccccc);
  background-image: linear-gradient(to bottom, #e0e0e0, #cccccc);
}

.graph .load {
  position: relative;
  top: rem(-135px);
}

.graph .absolute-load {
  position: absolute;

}

.market-item-change {
  flex: 0 1 rem(60px);
}

.resolution-flex {
  flex-flow: wrap;
  justify-content: space-around;
}

.qrdiv {
  border: 0px solid red;
  flex: 0 1 rem(82px) !important;
}

.qr {
  width: rem(40px);
}

.qrdiv2 {
  border-radius: rem(3px);
  overflow: hidden;
  position: relative;
  top: rem(28px);
  border: 1px solid #348313;
  display: block !important;
  width: rem(110px);
  height: rem(110px);
  background: #ffffff;
}

.order-div-address {
  display: flex;

  flex-direction: row;
  align-items: flex-start;
}

.order-status-area {
  padding-top: rem(33px);
  border: 0px solid red;
  line-height: rem(20px);
  font-size: rem(12px);
  padding-left: rem(15px);

}

.norec {
  box-sizing: border-box;
  display: block;
  width: 100%;
  font-size: rem(12px);
  border-bottom: 1px solid #34831322;
  line-height: rem(40px);
  height: rem(40px);
}

.order-bar h3 {
  margin-top: 0;
}

.preloader-loading-qr {
  height: 100%;
  width: 100%;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.info-graph-area {
  margin-top: rem(60px);
  display: flex;
  flex-direction: row;
}

.tracker {
  margin-bottom: rem(30px);
  text-align: left;
}

.expire-div {
  position: absolute;
  padding-right: rem(120px);
}

.wallet-link {
  flex-grow: 0;
  background: url("/img/link.svg") no-repeat center center;
  padding: rem(2px) rem(2px);
  width: rem(27px);
  height: rem(27px);
  position: relative;
  top: rem(6px);
  border-radius: rem(35px);
  background-size: 60%;
  margin-right: rem(-10px);
}

.wallet-link:hover {
  background: #34831322 url("/img/link.svg") no-repeat center center;
  background-size: 60%;
  border-radius: rem(20px);
}