@import './rem.scss';

@media screen and (min-width: 10px) {

  .home-detail-block .border-div {
    margin-top: rem(30px);
  }

  .home-main-block .div-left, .home-main-block .div-right {
    min-width: rem(0px);
    //background-position: center center;
  }

  .home_block_main {
    padding: rem(30px);
    background-position: rem(-150px) rem(-140px);
  }

  .home_block_main .spacer {
    height: rem(40px);
  }

  .home_block_main .left-block h1 {
    font-size: rem(24px);
    line-height: rem(28px);
  }

  .key {
    display: none;
  }

  .home-main-block-text {
    max-width: 100%;
  }

  .home_block_main .left-block {
    flex: 1;
  }

  .home_block_main .right-block {
    flex: 0;
  }

  .home-detail-block .border-div .item {
    flex: 1 1 50%;
  }

  .home-detail-block .i1 {
    border: 0;
    border-right: 1px solid rgba(217, 185, 123, 0.17);
    border-bottom: 1px solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i2 {
    border: 0;
    border-bottom: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i3 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
    border-bottom: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i4 {
    border: 0;
    border-bottom: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i5 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i6 {
    border: 0;
  }

  h3 {
    font-size: rem(18px);
  }

  .home-strategy .image-block {

  }

  .home-strategy .image {

  }

  .home-strategy .text-block {
    width: 100%;
  }

  .home-news .item {
    flex: 1 1 100%;
  }


  .home-main-block .div-right {
    background-position: left rem(-180px) top rem(-138px);
  }

  .home-strategy .div-wrap {
    flex-flow: wrap;
    border: 0;
    flex-direction: column-reverse;
  }

  .strategy-image-text-div {
    min-width: rem(325px);
  }

  .adv-item {
    padding-right: rem(0px);
  }

  .purity .text-big {
    font-size: rem(30px);
  }

  .envelope {
    display: none;
  }

}

@media screen and (min-width: 480px) {

  .strategy-image-text-div {
    min-width: rem(380px);
  }

  .home_block_main .left-block h1 {
    font-size: rem(28px);
    line-height: rem(34px);
  }

  .home-main-block-text {
    max-width: 100%;
  }

  .home_block_main .left-block {
    flex: 1;
  }

  .home_block_main .right-block {
    flex: 0;
  }

  .home-detail-block .border-div .item {
    flex: 1 1 33%;
  }

  .home-detail-block .i1 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
    border-bottom: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i2 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
    border-bottom: rem(1px) solid rgba(217, 185, 123, 0.17);

  }

  .home-detail-block .i3 {
    border: 0;
    border-bottom: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i4 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i5 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i6 {
    border: 0;
  }

  h3 {
    font-size: rem(23px);
  }

  .home-strategy .image-block {
    margin-top: 0;
    border: 0 solid red;
  }

  .strategy-image-text-div {
    width: 100%;
  }

  .home-strategy .text-block {
    margin-top: rem(50px);
    width: 100%;
  }

}

@media screen and (min-width: 768px) {

  .home-detail-block .border-div {
    margin-top: rem(50px);
  }

  .home_block_main .spacer {
    height: rem(60px);
  }

  .home-main-block .div-left, .home-main-block .div-right {
    min-width: rem(50px);
  }

  .strategy-image-text-div {
    min-width: rem(425px);
  }

  .home_block_main .left-block h1 {
    font-size: rem(28px);
    line-height: rem(34px);
  }

  .key {
    width: rem(204px);
    height: rem(354px);
    display: block;
  }

  .home-main-block-text {
    max-width: rem(500px);
  }

  .home_block_main .left-block {
    flex: 2;
  }

  .home_block_main .right-block {
    flex: 1;
  }

  .home-detail-block .border-div .item {
    flex: 1 1 33%;
  }

  .adv-item {
    flex: 1 1 50%
  }

  h3 {
    font-size: rem(24px);
  }

  .home-strategy .image-block {
    visibility: visible;
    width: auto;
  }

  .home-news .item {
    flex: 1 1 50%;
  }

  .home_block_main {
    padding: 0;
    padding-bottom: rem(70px);
    background-position: rem(-240px) rem(-120px);
  }

  .home-main-block .div-right {
    background-position: left 0 top rem(-138px);
  }

  .home-strategy .image-block {
    margin: rem(100px) 0 0 rem(50px);
  }

  .home-strategy .image-block {
    margin-top: rem(50px);
  }

  .adv-item {
    padding-right: rem(50px);
  }

  .purity .text-big {
    font-size: rem(38px);
  }

}

@media screen and (min-width: 1060px) {

  .home_block_main {
    background-size: 90%;
  }

  .home-main-block .div-left {
    background-size: 1850%;
  }

  .home-main-block .div-right {
    background-size: 1850%;
  }


  .home_block_main .left-block h1 {
    font-size: rem(32px);
    line-height: rem(42px);
  }

  .footer-div-copyright {
    text-align: right;
    float: right;
  }

  .key {
    border: 0 solid red;
    width: rem(213px);
    height: rem(473px);
    margin-right: rem(70px);
  }

  .home-main-block-text {
    max-width: rem(500px);
  }

  .home_block_main .left-block {
    flex: 2;
  }

  .home_block_main .right-block {
    flex: 1;
  }

  .home-detail-block .border-div .item {
    flex: 1 1 10%;
  }

  .home-detail-block .i1 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i2 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i3 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i4 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i5 {
    border: 0;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.17);
  }

  .home-detail-block .i6 {
    border: 0;
  }

  .adv-item {
    flex: 1 1 50%;
  }

  h3 {
    font-size: rem(27px);
  }

  .home-strategy .text-block {
    width: auto;
  }

  .home-strategy .image-block {
    margin: rem(100px) 0 0 rem(50px);
  }

  .home-news .item {
    flex: 1 1 33%;
  }

  .home_block_main {
    background-position: rem(-134px) rem(-140px);
  }

  .home-strategy .div-wrap {
    flex-flow: nowrap;
  }

  .envelope {
    display: block;
  }

}

@media screen and (min-width: 1360px) {

  .home_block_main {
    background-size: auto;
  }

  .home-main-block .div-left {
    background-size: auto;
  }

  .home-main-block .div-right {
    background-size: auto;
  }

}

