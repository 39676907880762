@import './rem.scss';

.paginator {
  text-align: center;
  justify-content: center;
  padding: rem(20px) 0;

}

.paginator .left, .paginator .right {
  line-height: rem(23px);
  display: inline-block;
  width: rem(35px);
  height: rem(23px);
  border: rem(1px) solid #DCBF85;
  border-radius: rem(3px);
}

.paginator div {
  margin: rem(5px);
  cursor: pointer;
}

.paginator div:hover {
  color: #2f2f2f;
  background-color: rgba(217, 185, 123, 0.21);
}

.paginator .left {
  background: url('/img/page-left.svg') no-repeat center;
}

.paginator .right {
  background: url('/img/page-right.svg') no-repeat center;
}

.paginator .page {
  height: rem(24px);
  line-height: rem(24px);
  padding: 0 rem(6px);
  display: inline-block;
  border: rem(1px) solid #DCBF85;
  box-sizing: border-box;
  border-radius: rem(3px);
  font-size: rem(12px);
  color: #2f2f2f;
}

.paginator .active {
  background-color: rgba(217, 185, 123, 0.91);
  color: #F6F3EA;
}

.paginator .active:hover {
 cursor: default;
  background-color: rgba(217, 185, 123, 0.91);
  color: #F6F3EA;

}