@import './rem.scss';

.gtfee .footer-block {
  background: #F6F3EA;
}

.gtfee .footer-block h3 {

  text-align: center;
  color: #CB9834;
  text-transform: none;
  margin-bottom: rem(10px);
}

.gtfee .footer-block .center-block {
  max-width: rem(800px);
  display: inline-block;
}

.gtfee .footer-block .center-block div {
  padding: rem(20px) rem(10px);
  font-size: rem(18px);
  color: #4D4C4B;
}