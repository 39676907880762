@import './rem.scss';

@media screen and (min-width: 10px) {

    .market-div {
        padding: rem(30px) rem(30px) rem(10px) rem(30px);
    }

    .market-div-additional {
        padding: rem(40px) rem(30px) rem(30px) rem(30px);
    }

    .order-div-address {
        flex-wrap: wrap;
    }

    .i10 {
        padding-right: rem(0px) !important;
        padding-bottom: rem(25px);
    }

    .info-graph-area {
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .graph {
        margin-top: rem(30px);
        flex: 1 1 55%;
    }

    .graph-price {
        padding: 0 rem(0px) 0 0;
    }

    .market-item {
        flex: 1 1 100%;
    }

    .item-margintop {
        margin-top: rem(20px);
    }

    .button-exchange {
        margin-left: rem(50px);
        margin-right: rem(50px);
    }

    .market-div-additional .i1, .market-div-additional .i3 {
        padding-right: 0;
    }

    .market-div-additional .form {
        flex: 1 1 100%;
    }

    .market-div-additional .form-button div {
        flex-basis: 10%;
    }

    .form-button .button-back, .form-button .button-exchange-v2, .form-button .button-exchange, .form-button .button-exchange-loading {
        background-image: none;
        padding: 0 rem(20px) rem(2px) rem(20px);
        width: 100%;
        margin-left: 0;
        margin-top: rem(20px);
    }

    .partners-div {
        flex-flow: wrap;
        float: left;
    }

    .partners-div .logo {
        width: auto;
        padding-right: 0;
        padding-bottom: rem(20px);
    }


    .partners-div .url a {
        margin-top: 10px;
        display: block;
    }
    
    .market-item-change {
        display: none;
    }

    .form.i4 {
        display: none;
    }



}

@media screen and (min-width: 720px) {


    .partners-div {
        flex-flow: nowrap;
        justify-content: space-around;
    }

    .partners-div .logo {
        width: rem(200px);
        padding-right: rem(40px);
        padding-bottom: 0;
    }

    .partners-div .url a {
        margin-top: 0;
    }

}

@media screen and (min-width: 1000px) {

    .market-div {
        padding: rem(60px) rem(50px) rem(50px) rem(50px);
    }

    .market-div-additional {
        padding: rem(50px) rem(50px) rem(50px) rem(50px);
    }

    .form.i4 {
        display: block;
    }

    .button-exchange {
        margin-left: rem(50px);
        margin-right: rem(50px);
        background: #c3992c url('/img/right-white.svg') no-repeat right rem(20px) center;
    }

    .button-exchange-loading {
        margin-left: rem(50px);
        margin-right: rem(50px);
        background: #c3992c url('/img/loading-bubbles-whitessvg') no-repeat right rem(20px) center;
    }


    .form-button .button-exchange {
        background: #c3992c url('/img/right-white.svg') no-repeat right rem(20px) center;
        padding: rem(1px) rem(70px) rem(2px) rem(40px);
        margin-left: rem(50px);
    }

    .form-button .button-exchange-loading {
        background: #c3992c url('/img/loading-bubbles-white.svg') no-repeat right rem(20px) center;
        padding: rem(1px) rem(70px) rem(2px) rem(40px);
        margin-left: rem(50px);
    }

    .form-button .button-back, .form-button .button-exchange, .form-button .button-exchange-loading, .form-button .button-exchange-v2 {
        width: auto;
    }

    .form-button .button-exchange-v2 {
        margin-left: rem(40px);
    }

    .item-margintop {
        margin-top: rem(0px);
    }

    .market-item-change {
        display: block;
    }

    .info-graph-area {
        flex-wrap: nowrap;
    }

    .graph {
        margin-top: rem(15px);
        flex: 0 1 53%;
    }

    .graph-price {
        padding: 0 rem(40px) 0 0;
    }

    .market-item-button {
        flex: 0 0 200px;
        margin-top: 0;
    }

    .button-exchange, .button-exchange-loading {
        margin-left: 50px;
        margin-right: 0;
    }

    .market-div-additional .i1, .market-div-additional .i3 {
        padding-right: 50px;
    }

    .market-div-additional .form {
        flex: 1 1 40%;
    }

    .market-item {
        flex: 1 1 10%;
        margin-top: 0;
    }

    .i10 {
        padding-right: rem(40px) !important;
    }

    .order-div-address {
        flex-wrap: wrap;
    }

}
