@import './rem.scss';

.fee-bar {
    background: linear-gradient(58.23deg, #262626 7.54%, #0D0D0D 99.45%);
}

.fee-bar .text {
    line-height: rem(24px);
    text-align: left;
    color: #b2b2b2;
    font-size: rem(14px);
}

.fee-bar .div-text {
    flex: rem(3px);
}


table.table-fee {width: 100%; border: 0; border-collapse: collapse;}
table.table-fee thead {font-weight: bold;}

table.table-fee th {
    white-space: nowrap;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.3);
    text-transform: uppercase;
    background-color: #000000;
    vertical-align: middle;
    height: rem(50px);
    line-height: rem(50px);
    color: white;
    font-size: rem(18px);
}

table.table-fee .small {
    font-size: rem(12px);
}

table.table-fee td, table.table-fee .resolution-th  {
    text-align: left;
    color: black;
    font-size: rem(14px);
    background: #edead8;
    border-right: rem(1px) solid rgba(217, 185, 123, 0.3);

    padding: 0 rem(30px);
    height: rem(50px);

    align-items: center;
}

table.table-fee td.green {
    color: #009c2c;
    font-weight: 600;
}

table.table-fee td.red {
    color: #d10000;
}


