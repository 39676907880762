@import './rem.scss';

@media screen and (min-width: 10px) {
    .item-news .item {
        flex: 1 1 100%;
    }
    .list-news .pre_image {
        margin-right: 0;
        display: none;
    }

}

@media screen and (min-width: 768px) {
    .item-news .item {
        flex: 1 1 50%;
    }
    .list-news .pre_image {
        display: block;
        margin-right: rem(20px);
    }

}

@media screen and (min-width: 1060px) {
    .item-news .item {
        flex: 1 1 33.33%;
    }
}
