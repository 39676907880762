
.loading {
    position: relative;
    top: -1px;
    display: block;
    border: 0px solid red;
    width: 30px;
    height: 18px;
    box-sizing: border-box;
    padding: 0;
    margin: 0 10px;
    outline: none;
    background: url("/img/loading-bubbles-white.svg") no-repeat center center;

}

.loading.dark {
    background: url("/img/loading-bubbles-dark.svg") no-repeat center center;
}

.loading.white {
    background: url("/img/loading-bubbles-white.svg") no-repeat center center;
}

.big {
    transform: scale(2.5);
}

.medium {
    transform: scale(1.5);
}