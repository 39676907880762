@import './rem.scss';

@media screen and (min-width: 10px) {

  .audits-bar2 .audit {
    background-size: 80%;
  }

}

@media screen and (min-width: 768px) {

  .audits-bar2 .audit {
    background-size: 40%;
  }

}
