@import './rem.scss';

.news-item-bar {
  background-color: #F6F3EA;
}

.news-item-bar h1 {
  color: #2F2F2F;
  font-size: 30px;
  margin: rem(5px) rem(0px) rem(10px) 0;
}


.news-item-bar h2 {
  color: #CB9834;
}

.news-item-bar .text {
  font-size: rem(14px);
  line-height: rem(20px);
}

.item-news {
  margin: 0 rem(-15px);
  display: flex;
}

.item-news .item {
  padding: 0 rem(15px);
  box-sizing: border-box;
  margin-top: rem(30px);
}

.news-item-bar ul li {
  margin-bottom: rem(14px);
}


.item-news .text {
  font-size: rem(16px);
  margin-top: rem(10px);
  //background: linear-gradient(272.37deg, rgba(219, 212, 191, 0.3) 7.57%, rgba(185, 175, 146, 0.3) 121.95%);
  border-radius: rem(3px);
  padding: rem(20px);
  text-align: left;
  box-sizing: border-box;
  width: 100%;
  height: calc(100% - 220px) !important;
  color: #2F2F2Fdd;
}

.item-news .item .text b {
  line-height: rem(23px);
  font-size: rem(16px);
  text-transform: uppercase;
  color: black;
  margin-bottom: rem(100px);
  font-weight: 600;
}

.item-news .item .text span {
  font-weight: 400;
  box-sizing: border-box;
  line-height: rem(23px);
  font-size: rem(13px);
  color: #2f2f2f;
}

.list-news {
  display: block;
  background-color: #F6F3EA;
}

.list-news h1 {
  color: #2f2f2f;
}

.list-news .item .image, .item-news .item .image {
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
  background-position: center;
  background-size: cover;
  -webkit-transform:scale(1.05);
  -moz-transform:scale(1.05);
  -o-transform:scale(1.05);
}

.list-news .item .image:hover, .item-news .item .image:hover  {
  -webkit-transform:scale(1.1);
  -moz-transform:scale(1.1);
  -o-transform:scale(1.1);
}

.list-news .pre_image {
  flex: 0 0 rem(240px);
  border-radius: rem(3px);
  z-index: 1;
  display: block;
  background: url('/img/news-default.jpg') center center no-repeat;
  background-size: cover;
  height: rem(170px);
  overflow: hidden;
  margin-right: rem(24px);
}

.list-news div {
  text-align: left;
}

.list-news .subject {
  line-height: rem(20px);
  display: block;
  padding-top: rem(3px);
  font-weight: 600;
  text-transform: uppercase;
  font-size: rem(16px);
}

.list-news .text {
  margin-top: rem(18px);
  line-height: rem(22px);
  padding-top: rem(3px);
  font-weight: 400;
  font-size: rem(14px);
}

.list-news .item {
  margin-top: 50px;
}

.list-news .url, .item-news .item .text .url {
  padding: rem(1px) rem(30px) rem(1px) 0;
  display: inline-block;
  margin-top: rem(15px);
  color: #CB9834;
  cursor: pointer;
  font-size: rem(14px);
  background: url('/img/url.svg') no-repeat right;
}

.item-news .text {
  height: calc(100% - 290px);
}

.list-news .url:hover, .item-news .item .text .url:hover {
  color: #CB9834;
  text-decoration: underline;
}

.list-news .content {
  padding-bottom: rem(30px);
}

.list-news .item .time span, .item-news .item .time span {
  display: block;
  text-align: left;
  font-size: rem(12px);
  margin-top: rem(10px);
  margin-bottom: rem(10px);
}

.item-news .pre_image {

  //border: rem(1px) solid #CB9834;
  border-radius: rem(3px);
  z-index: 1;
  display: block;
  background: url('/img/news-default.jpg') center center no-repeat;
  background-size: cover;
  width: 100%;
  height: rem(200px);
  overflow: hidden;
  margin-right: rem(24px);
}
.time {
  display: block;
  padding: rem(10px) 0;
  color: #656565;
  font-size: rem(12px);
}
