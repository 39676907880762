@import './rem.scss';

.about-bar {
    padding: rem(70px) rem(50px) rem(100px) rem(50px);
    background: linear-gradient(58.23deg, #262626 7.54%, #0D0D0D 99.45%);
}

.about-bar text {
    line-height: rem(24px);
    text-align: left;
    color: #b2b2b2;
    font-size: rem(14px);
}

.about-bar .div-text {
    flex: rem(3px);
}
