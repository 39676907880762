@import './rem.scss';

@media screen and (min-width: 10px) {

    html, body, .viewport {
        font-size: 12px;
    }

    .footer-div-copyright div {
        justify-content: center;
    }


    .bottom-around {
        flex-flow: row wrap;
        justify-content: space-around;
    }

    .footer-div-resources, .footer-div-about {
        margin-right: rem(40px);
        min-width: rem(300px);
    }

    .footer-div-copyright {
        padding: 0 rem(50px);
        margin-top: rem(50px);
        //margin-bottom: rem(30px);
    }

    .footer-menu {
        flex-flow: row wrap;
        width: rem(180px);
    }

    .top-line-header .logo {
        float: left;
        border: 0 solid red;
        margin: rem(5px) 0 0 0;
        width: rem(176px);
        height: rem(40px);
        display: block;;
    }

    .top-line-header-logo .logo {
        margin: rem(14px) 0 0 0;
        width: 0;
        height: 0;
        display: none;;
    }

    menu a {
        line-height: rem(38px);
        display: block;
        font-size: rem(22px);
        margin: 0 0 0 rem(0px);
    }

    menu {
        text-align: center;
    }

    .top-line-header-logo {
        height: auto;
    }

    .left-right-padding {
        padding: 0 rem(30px);
    }

    .bar-padding {
        padding: rem(30px) rem(30px) rem(30px) rem(30px);
    }

    .bar-padding-without-bottom {
        padding: rem(30px) rem(30px) rem(5px) rem(30px);
    }

    .bar-padding-without-top {
        padding: rem(0px) rem(30px) rem(30px) rem(30px);
    }

}

@media screen and (min-width: 480px) {

    html, body, .viewport {
        font-size: 14px;
    }

    .bottom-around {
        flex-flow: row wrap;
        justify-content: space-around;
    }

    .footer-div-resources, .footer-div-about {
        margin-right: rem(40px);
        min-width: rem(100px);
        margin-top: 0;
    }


    .footer-menu {
        flex-flow: row wrap;
        width: rem(180px);
    }

    .top-line-header .logo {
        float: left;
        border: 0 solid red;
        margin: rem(6px) 0 0 0;
        width: rem(176px);
        height: rem(38px);
        display: block;;
    }

    .top-line-header-logo .logo {
        margin: rem(14px) 0 0 0;
        width: 0;
        height: 0;
        display: none;;
    }

    menu a {

    }

    menu {
        text-align: center;
    }

}

@media screen and (min-width: 768px) {

    html, body, .viewport {
        font-size: 16px;
    }

    .bottom-around {
        flex-flow: row wrap;
        justify-content: space-around;
    }

    .footer-div-resources {
        margin-right: rem(40px);
        min-width: rem(280px);
        margin-top: rem(0px);
    }

    .footer-div-about {
        margin-right: rem(40px);
        min-width: rem(280px);
        margin-top: rem(40px);
    }


    .footer-menu {
        flex-flow: row nowrap;
        width: auto;
    }

    .top-line-header .logo {
        float: left;
        border: 0px solid red;
        margin: rem(6px) 0 0 0;
        width: rem(176px);
        height: rem(38px);
        display: block;;
    }

    .top-line-header-logo {
        height: rem(72px);
    }

    .top-line-header-logo .logo {
        margin: rem(14px) 0 0 0;
        width: 0;
        height: 0;
        display: none;;
    }

    menu a {
        display: inline-block;
        font-size: rem(14px);
        margin: 0 rem(15px) 0 rem(15px);
        line-height: normal;
        text-align: right;

    }

    menu {
        text-align: center;
    }

    .left-right-padding {
        padding: 0 rem(50px);
    }

    .bar-padding {
        padding: rem(60px) rem(50px) rem(60px) rem(50px);
    }

    .bar-padding-without-bottom {
        padding: rem(60px) rem(50px) rem(10px) rem(50px);
    }

    .bar-padding-without-top {
        padding: rem(0px) rem(50px) rem(50px) rem(50px);
    }
}

@media screen and (min-width: 1060px) {

    html, body, .viewport {
        font-size: 18px;
    }

    html, body, .viewport {
        font-size: 1.29vw;
    }


    .bottom-around {

    }

    .footer-div-resources, .footer-div-about {
        margin-right: rem(40px);
        min-width: rem(280px);
        margin-top: 0;
    }

    .footer-menu {
        flex-flow: row nowrap;
        width: auto;
    }

    .top-line-header .logo {
        margin: rem(14px) 0 0 0;
        width: 0;
        height: 0;
        display: none;;
    }

    .top-line-header-logo .logo {
        margin: rem(12px) 0 0 0;
        width: rem(218px);
        height: rem(50px);
        display: block;;
        border: 0px solid red;
    }

    menu a {
        border: 0px solid red;
        font-size: rem(14px);
        //margin: 0 0 0 rem(2px);
        line-height: normal;
        text-align: right;
    }

    menu {
        text-align: right;
    }
}

@media screen and (min-width: 1100px) {
    menu a {
        margin: 0 0 0 rem(40px);
    }
}

@media screen and (min-width: 1160px) {
    menu a {
        //margin: 0 0 0 rem(40px);
    }
    .footer-div-copyright {
        text-align: center;
    }
}

@media screen and (min-width: 1360px) {

    html, body, .viewport {
        font-size: 18px;
    }

    .footer-div-copyright div {
        justify-content: flex-end;
    }
    .footer-div-copyright {
        text-align: right;
        float: right;
        padding: 0 0;
        margin-top: 0;
    }
}
