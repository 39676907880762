@import './rem.scss';


@media screen and (min-width: 10px) {
  .support-div {
    flex-flow: wrap;
    justify-content: space-around;
  }
  .support-bar-form .address {
    margin-left: rem(0px);
    margin-top: rem(50px);
  }
  .support-bar .div-image {
    display: none;
  }
}



@media screen and (min-width: 768px) {
  .support-div {
   flex-wrap: nowrap;
  }
  .support-bar-form .address {
    margin-left: rem(30px);
    margin-top: rem(0px);

  }

  .support-bar .div-image {
    display: block;
  }
}

@media screen and (min-width: 1060px) {

}

