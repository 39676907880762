
.tip {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  top: -60px;
  align-items: center;
}

.tip .in-span {
  display: inline-block;
  padding: 14px 10px;
  background: red;
  color: white;
  font-size: 13px;
  border-radius: 5px;
  width: auto;
  box-shadow: #2F2F2F99 1px 1px 12px;
}

.tip .blue {
  background: #346a92;
}

.rpt-arrow {
  position: relative;
  transform: rotate(45deg);
  width: 15px;
  height: 15px;
  margin:3px;
  z-index: 1;
  background: red;
  top: -14px;
}

@keyframes rpt-bounce {
  0%   {transform: scale(0.8); opacity: 0.1}
  60%  {transform: scale(1.1); opacity: 1}
  100%  {transform: scale(1);}
}

@keyframes rpt-bounce-out {
  20% { transform: scale(1.1); }
  100% { transform: scale(0.8); opacity: 0.1 }
}

.tipanim {
  animation-name: rpt-bounce;
  animation-duration: 0.2s;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}